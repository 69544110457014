/* eslint-disable import/no-import-module-exports */
import {legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// // eslint-disable-next-line import/prefer-default-export
// export function configureStore(initialState) {
//   const store = createStore(
//     reducers,
//     initialState,
//     composeEnhancers(applyMiddleware(...middlewares))
//   );

//   sagaMiddleware.run(sagas);

//   if (module.hot) {
//     module.hot.accept('./reducers', () => {
//       // eslint-disable-next-line global-require
//       const nextRootReducer = require('./reducers');
//       store.replaceReducer(nextRootReducer);
//     });
//   }

//   return store;
// }

const store = createStore(reducers,composeEnhancers(applyMiddleware(...middlewares)));

sagaMiddleware.run(sagas);

export default store;
// export default configureStore;